.addRecipeButton {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: fixed;
    right: 25px;
    bottom: 25px;
    background-color: #E84241;
    font-size: 48px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);
    cursor: pointer;
    transition: all 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFE;
}

.addRecipeButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .4);
}