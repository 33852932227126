.ingredientsList {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ingredientsList__item {
    height: 75px;
    background-color: #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid #777777;
    color: #FFFFFE;
    font-size: 24px;
}

.ingredientsList__item__container {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.ingredientsList__item__delete {
    font-size: 16px;
    color: #FFFFFE;
    cursor: pointer;
    background-color: #E84241;
    padding: 5px;
    border-radius: 5px;
}