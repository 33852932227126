@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEEEE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container--full {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
}

button {
  border: none;
  border-radius: 5px;
  width: 100%;
  color: #FFFFFE;
  background-color: #E84241;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  height: 50px;
  margin: 15px 0;
  cursor: pointer;
}

.btn--delete {
  background-color: #777777;
  color: #FFFFFE;
}

.container--padded {
  padding: 5px 15px;
}

section {
  margin-bottom: 25px;
  margin-top: 5px;
}

i {
  font-size: 64px;
}

.back-button {
  justify-self: flex-start;
  align-self: flex-start;
  border-radius: 50%;
  background-color: #E84241;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .4);
  cursor: pointer;
  font-size: 36px;
  transform: scaleX(-1);
}