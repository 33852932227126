.recipe {
    width: 100%;
    margin: 0;
    background-color: #262823;
    min-height: 100vh;
}

.recipe__title {
    background-color: #404040;
    color: #FFFFFE;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
    padding: 15px;
}

.sectionLabel {
    color: #808080;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
}

.recipe__ingredientAdd {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.recipe__ingredientAdd input {
    width: 100%;
    margin: 10px 0;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: #FFFFFE;
    padding: 15px;
    font-size: 18px;
}

.recipe__ingredientAdd label {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #808080;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-top: 10px;
}

.recipe__ingredientAdd span {
    text-align: center;
    color: #808080;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    margin-top: 15px;
}

.recipe__ingredientAdd textarea {
    min-height: 250px;
    padding: 15px;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.rating--interactive {
    width: 100%;
    display: flex;
    justify-content: center;
}