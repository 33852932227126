@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEEEEE;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container--full {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
}

button {
  border: none;
  border-radius: 5px;
  width: 100%;
  color: #FFFFFE;
  background-color: #E84241;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-size: 24px;
  font-family: 'Roboto', sans-serif;
  height: 50px;
  margin: 15px 0;
  cursor: pointer;
}

.btn--delete {
  background-color: #777777;
  color: #FFFFFE;
}

.container--padded {
  padding: 5px 15px;
}

section {
  margin-bottom: 25px;
  margin-top: 5px;
}

i {
  font-size: 64px;
}

.back-button {
  justify-self: flex-start;
  align-self: flex-start;
  border-radius: 50%;
  background-color: #E84241;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .4);
  cursor: pointer;
  font-size: 36px;
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
}
.recipeCard {
    margin: 10px;
    cursor: pointer;
    max-width: 500px;
    width: 100%;
}

.recipeCard__title {
    background-color: #404040;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 35px;
    padding: 10px;
    color: #FFFFFE;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
}

.recipeCard__body {
    background-color: #262823;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 50px;
    padding: 10px;
}
.star {
    width: 24px;
    height: 24px;
}
.flame {
    width: 24px;
    height: 24px;
}
.recipe {
    width: 100%;
    margin: 0;
    background-color: #262823;
    min-height: 100vh;
}

.recipe__title {
    background-color: #404040;
    color: #FFFFFE;
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
    padding: 15px;
}

.sectionLabel {
    color: #808080;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-size: 18px;
    margin: 0;
    margin-bottom: 5px;
}

.recipe__ingredientAdd {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
}

.recipe__ingredientAdd input {
    width: 100%;
    margin: 10px 0;
    height: 50px;
    border: none;
    border-radius: 5px;
    background-color: #FFFFFE;
    padding: 15px;
    font-size: 18px;
}

.recipe__ingredientAdd label {
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    color: #808080;
    text-transform: uppercase;
    letter-spacing: 5px;
    margin-top: 10px;
}

.recipe__ingredientAdd span {
    text-align: center;
    color: #808080;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 24px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    margin-top: 15px;
}

.recipe__ingredientAdd textarea {
    min-height: 250px;
    padding: 15px;
    border-radius: 5px;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
}

.rating--interactive {
    width: 100%;
    display: flex;
    justify-content: center;
}
.editableName {
    width: 100%;
}

.title__name {
    text-align: right;
    margin: 0;
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    text-shadow: 2px 4px 5px rgba(0, 0, 0, .4);
    padding: 10px 0;
}

.title__name--editable {
    margin: 0;
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
    height: 56px;
    border: none;
    text-align: right;
    width: 100%;
}
.ingredientsList {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.ingredientsList__item {
    height: 75px;
    background-color: #404040;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    font-family: 'Roboto', sans-serif;
    border-bottom: 1px solid #777777;
    color: #FFFFFE;
    font-size: 24px;
}

.ingredientsList__item__container {
    display: flex;
    flex-flow: row nowrap;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
}

.ingredientsList__item__delete {
    font-size: 16px;
    color: #FFFFFE;
    cursor: pointer;
    background-color: #E84241;
    padding: 5px;
    border-radius: 5px;
}
.notes {
    color: #FFFFFE;
}

.notes__item {
    font-size: 22px;
    margin: 5px 0;
    display: flex;
    flex-direction: column;
}

.notes__item__delete {
    max-width: 250px;
    font-size: 16px;
    align-self: center;
}
.addRecipeButton {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: fixed;
    right: 25px;
    bottom: 25px;
    background-color: #E84241;
    font-size: 48px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .4);
    cursor: pointer;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFE;
}

.addRecipeButton:hover {
    -webkit-transform: translateY(-2px);
            transform: translateY(-2px);
    box-shadow: 0 5px 10px rgba(0, 0, 0, .4);
}
.rating {
  display: flex;
  flex-flow: row nowrap;
}

.recipeCardList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.App {
  position: relative;
}
