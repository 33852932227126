.rating {
  display: flex;
  flex-flow: row nowrap;
}

.recipeCardList {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.App {
  position: relative;
}